@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		display: flex;
		flex-direction: column;
		border-top: $logan-border-width-1 solid $logan-outline-gray;
		padding: $logan-spacing-24;
		height: 0px;

		flex: 1 1 auto;
		overflow: auto;

		.cardHeaderContainer {
			gap: $logan-spacing-4;
			margin-bottom: $logan-spacing-32;
		}

		.formContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;

			.inputContainer {
				gap: $logan-spacing-6;
				flex-wrap: wrap;
			}

			.inlineContainer {
				min-width: 264px;
				width: calc(50% - 12px);
			}

			.emptyContainer {
				width: 100%;
				height: $logan-spacing-24;
			}
		}

		.formStartContainer {
			justify-content: flex-start;
		}

		.mortgageHeaderContainer {
			gap: 90px;
			align-items: center;
			margin-bottom: 40px;
		}

		.mortgageInput {
			width: 210px;
		}

		.xmlNote {
			margin-bottom: $logan-spacing-32;
		}
	}
}

.footerContainer {
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}
