@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		display: flex;
		flex-direction: column;
		border-top: $logan-border-width-1 solid $logan-outline-gray;
		padding: $logan-spacing-24;
		height: 0px;

		flex: 1 1 auto;
		overflow: auto;

		.cardHeaderContainer {
			gap: $logan-spacing-4;
			margin-bottom: $logan-spacing-32;
		}

		.formContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;

			.sectionHeader {
				flex: 1;
				color: $logan-text-black;
				// TODO missing token
				min-width: 160px;
			}

			.inputContainer {
				flex-wrap: wrap;
				width: calc(100% - 12px);
				// max-width: 750px;
				align-items: center;
				justify-content: space-between;
				gap: $logan-spacing-6;

				.centeredLabel {
					display: flex;
					align-items: center;
				}

				.helperIconBtn {
					margin: $logan-spacing-0 $logan-spacing-0 $logan-spacing-0
						$logan-spacing-8;
					padding: $logan-spacing-4;

					.helperIcon {
						font-size: $logan-font-size-20;
						color: $logan-button-primary-enabled;
					}
				}
			}

			.presentHousing {
				padding-right: $logan-spacing-16;
			}
		}

		.bottomFormContainer {
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;
		}

		.paddedContainer {
			padding-left: $logan-spacing-32;
			padding-right: $logan-spacing-16;
		}
	}
}

.footerContainer {
	justify-content: space-between;
	// TODO missing token
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}
