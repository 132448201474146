@import "../../../styles/build/scss/variables";

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
	padding: 0px;

	p {
		width: 95%;
		text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	}
}

.MuiOutlinedInput-notchedOutline,
.Mui-disabled .MuiOutlinedInput-notchedOutline {
	border: 0;
	border-radius: $logan-border-radius-6;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
	border-radius: $logan-border-radius-6;
	border: $logan-border-width-2 solid $logan-destructive-enabled;
	background: rgba(236, 86, 86, 0.15);
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
	font-size: $logan-font-size-20;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters
	p {
	color: $logan-text-dark-green;
}
